$fontName: 'Gelica';

@mixin font-face(
  $font-name,
  $font-path,
  $font-weight: 400,
  $font-style: normal
) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: block;
    src: url('#{$font-path}.otf') format('opentype');
  }
}

@include font-face('#{$fontName}', './fonts/Gelica/gelica-light', 300);
@include font-face('#{$fontName}', './fonts/Gelica/gelica-regular', 400);
@include font-face('#{$fontName}', './fonts/Gelica/gelica-medium', 500);