@import-normalize;
@import './css/customised-animations.css';

:root {
  --neutral-900: #151717;
  --neutral-800: #1e2121;
  --neutral-700: #272b2b;
  --neutral-600: #636d6d;
  --neutral-500: #899090;
  --neutral-400: #afb4b4;
  --neutral-300: #d5d8d8;
  --neutral-200: #e6e8e8;
  --neutral-100: #f1f4f4;
  --neutral-50: #f7f9f9;
  --neutral-0: #ffffff;

  --brandTeal-700: #256163;
  --brandTeal-600: #2f7a7c;
  --brandTeal-500: #389295;
  --brandTeal-100: #e6f3f3;

  --red-400: #df5c64;

  --green-500: #62a15f;
  --green-400: #6fb36d;

  --pink-400: #bd3e6f;

  --purple-500: #594c94;
  --purple-400: #6f5fb4;

  --blue-400: #599acf;

  --yellow-400: #e1b033;

  --workplaceBlue-500: #345ba6;
  --workplaceBlue-400: #406fcb;
  --workplaceBlue-100: #ecf1fa;

  --black-900: #000000;
  --scrollbar-width: 16px;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

html.default-scrollbar {
  overflow-y: scroll;
}

html.overflow-hidden {
  overflow: hidden;
}

html.preserve-scroll-width {
  padding-right: var(--scrollbar-width);
}

html.preserve-scroll-width #site-header {
  padding-right: var(--scrollbar-width);
}

html.modal-opened #freshworks-container {
  display: none;
}

html[class*='sub-nav'] #site-header {
  z-index: 1500 !important;
  /* background-color: white; */
}

html[class*='sub-nav'] #site-header .header-background {
  /* background-color: var(--chakra-colors-neutral-0); */
}

.chakra-modal__content-container:has(.sub-nav-drawer) {
  z-index: 900 !important;
}

body {
  overflow: unset !important;
  padding-right: 0 !important;
}

#launcher {
  z-index: 1200 !important;
}

.grecaptcha-badge {
  z-index: 101;
}

.uppercase {
  text-transform: uppercase;
}

.titleLargeBold {
  font-family: Brandon;
  font-weight: 500;
  font-size: 58px;
  line-height: 62px;
  letter-spacing: -0.01em;
}

@media screen and (max-width: 600px) {
  .titleLargeBold {
    font-size: 38px;
  }
}

.h1Bold {
  font-family: Brandon;
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.h1Medium {
  font-family: Brandon;
  font-size: 28px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.displayBold {
  font-family: Brandon;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.h2Bold {
  font-family: Brandon;
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.h2Medium {
  font-family: Brandon;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.h3Bold {
  font-family: Brandon;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.h3Medium {
  font-family: Brandon;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.h3Regular {
  font-family: Brandon;
  font-size: 20px;
  line-height: 29px;
  font-weight: 400;
}

.subHeadingBold {
  font-family: Brandon;
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
}

.subHeadingMedium {
  font-family: Brandon;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}

.subHeadingRegular {
  font-family: Brandon;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.titleBold {
  font-family: Brandon;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.01em;
}

.p1Medium {
  font-family: Brandon;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
}

.p1Regular {
  font-family: Brandon;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.p2Bold {
  font-family: Brandon;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
}

.p2Medium {
  font-family: Brandon;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
}

.p2Regular {
  font-family: Brandon;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
}

.p3Medium {
  font-family: Brandon;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.p3Regular {
  font-family: Brandon;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.p3Bold {
  font-family: Brandon;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}

.caption1 {
  font-family: Brandon;
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.caption2 {
  font-family: Brandon;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.caption3 {
  font-family: Brandon;
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
.crossed {
  position: relative;
}
.crossed::before {
  content: '';
  display: block;
  position: absolute;
  top: 57%;
  right: 0;
  height: 0.5px;
  width: 100%;
  background-color: var(--red-400);
}
.crossed.grey::before {
  content: '';
  display: block;
  position: absolute;
  top: 57%;
  right: 0;
  height: 0.5px;
  width: 100%;
  background-color: var(--neutral-500);
}

.no-scroll {
  overflow: hidden;
}

.search-bar:focus {
  background-color: white;
  caret-color: #389295;
}
