@mixin font-face(
  $font-family,
  $font-file-name,
  $font-weight: 400,
  $font-style: normal
) {
  @font-face {
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: block;
    src: url('fonts/#{$font-file-name}.woff2') format('woff2'),
      url('fonts/#{$font-file-name}.woff') format('woff'),
      url('fonts/#{$font-file-name}.ttf') format('truetype'),
      url('fonts/#{$font-file-name}.svg##{$font-file-name}') format('svg');
  }
}

@include font-face('JennaSue', 'JennaSue', normal, normal);
@include font-face('JennaSuePro', 'JennaSuePro', normal, normal);

@include font-face('ProximaNova', 'ProximaNova-Thin', 100);
@include font-face('ProximaNova', 'ProximaNova-Light', 300);
@include font-face('ProximaNova', 'ProximaNova-Regular', 400);
@include font-face('ProximaNova', 'ProximaNova-Medium', 500);
@include font-face('ProximaNova', 'ProximaNova-Semibold', 600);
@include font-face('ProximaNova', 'ProximaNova-Bold', 700);
@include font-face('ProximaNova', 'ProximaNova-Extrabld', 800);
@include font-face('ProximaNova', 'ProximaNova-Black', 900);

@include font-face('ProximaNova-Light', 'ProximaNova-Light', 300);
@include font-face('ProximaNova-Regular', 'ProximaNova-Regular', 400);
@include font-face('ProximaNova-Semibold', 'ProximaNova-Semibold', 600);
@include font-face('ProximaNova-Bold', 'ProximaNova-Bold', 700);
@include font-face('ProximaNova-Extrabld', 'ProximaNova-Extrabld', 800);
