/* all kinds of animations */

/* For the caoursel tiles: pop upwards on hover */
.animate.animate__carousel-tile-pop {
  transition: all 0.5s ease 0.1s;
}

.animate__carousel-tile-pop:hover,
.animate__carousel-tile-pop:focus,
.animate__carousel-tile-pop:active {
  background: transparent;
}

@media (min-width: 960px) {
  .animate__carousel-tile-pop:hover {
    transform: translate(0, -8px);
    box-shadow: 0 10px 40px -10px rgba(24, 24, 24, 0.5);
  }
}

.animate-item-tile {
  transition: transform 0.2s ease-in;
}

.animate-item-tile:hover {
  transform: translateY(-6px);
}
